/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {Prose} from '@nikolovlazar/chakra-ui-prose';
import React, {useEffect, useRef} from 'react';
import ReactTimeago from 'react-timeago';
import agoFormatter from '../../../helpers/agoFormatter';
import {CommentRepository, ReactionRepository} from '@amityco/ts-sdk';
import {
  FaEdit,
  FaEllipsisV,
  FaHeart,
  FaRegComment,
  FaRegHeart,
  FaThumbtack,
  FaTrash,
} from 'react-icons/fa';
import {useMutation} from '@tanstack/react-query';
import DeleteConfirmPopup from '../../../components/Common/DeleteConfirmPopup';
import {AmitySdkPost} from '../../../types/amity/AmitySdkPost';
import {useUpdateEffect} from 'react-use';
import {AmitySDKcommentType} from '../../../types/AmityGetCommentByIdResponse';
import SingleCommentThread from '../../../components/NotificationsModal/SingleCommentThread';
import NotificationSendCommentInput from '../../../components/NotificationsModal/NotificationSendCommentInput';
import CoachBadge from '../../../components/Common/Badges/CoachBadge';
import EditPostModal from '../EditPostModal';
import {
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  MDXEditor,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import {AxiosInstance} from '../../../api/_AxiosInstance';
import {sharedMarkdownStylesString} from '../../../components/MDXEditorComponent/sharedStyles.ts';

interface PostDetailsProps {
  post: AmitySdkPost;
  onPinPost: () => void;
  onUnpinPost: () => void;
  onDeletePost: () => void;
  onLikePost: () => void;
  onUnlikePost: () => void;
  setPostCommentsCount: (postId: string, count: number) => void;
  onUpdatePost: (post: Partial<AmitySdkPost>) => void;
  isPinned?: boolean;
  canPinPost?: boolean;
}

const PostDetails: React.FC<PostDetailsProps> = props => {
  const {
    post,
    isPinned = false,
    canPinPost = true,
    onPinPost,
    onUnpinPost,
    onDeletePost,
    onLikePost,
    onUnlikePost,
    setPostCommentsCount,
    onUpdatePost,
  } = props;

  const [comments, setComments] = React.useState<AmitySDKcommentType[] | null>(null);
  const [commentsError, setCommentsError] = React.useState<any>(null);
  const [loadingComments, setLoadingComments] = React.useState(false);

  const [commentText, setCommentText] = React.useState('');
  const [isViewingComments, setIsViewingComments] = React.useState(false);
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null);

  const {isOpen, onClose, onOpen} = useDisclosure();
  const {
    isOpen: isOpenEdiPost,
    onOpen: onOpenEditPost,
    onClose: onCloseEditPost,
  } = useDisclosure();

  const [hasMoreComments, setHasMoreComments] = React.useState<boolean | undefined>(false);
  const loadMoreFnRef = useRef<(() => void) | undefined>(null);

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    if (!isViewingComments || loadingComments || comments !== null) {
      return;
    }

    if (post.commentsCount === 0) {
      setComments([]);
    }
  }, [isViewingComments]);

  useEffect(() => {
    const commentsPostParams: Amity.CommentLiveCollection = {
      referenceType: 'post',
      referenceId: post.postId,
      dataTypes: {
        values: ['text'],
        matchType: 'exact',
      },
      limit: 10,
    };
    const unsubscribe = CommentRepository.getComments(
      commentsPostParams,
      ({data, onNextPage, hasNextPage, loading, error}) => {
        if (error) {
          setLoadingComments(false);
          setCommentsError('There was an error getting the comments.');

          return;
        }
        if (loading) {
          setLoadingComments(true);
          return;
        }
        if (data) {
          setLoadingComments(false);
          setComments(data as unknown as AmitySDKcommentType[]);
          setCommentsError(null);
        }
        setHasMoreComments(hasNextPage);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loadMoreFnRef.current = onNextPage;
      },
    );

    return unsubscribe;
  }, []);

  useEffect(() => {
    mdxEditorRef.current?.setMarkdown(post.data.text);
  }, [post.data.text]);

  const pinPostMutation = useMutation({
    mutationFn: async () => {
      if (!canPinPost) {
        window.toast({
          description: 'Pinned posts limit reached.',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
        return;
      }

      await AxiosInstance.put(`/admin/communities/amity-post/${post.postId}`, {
        tags: [...post.tags, 'pinned'],
      });

      onPinPost();
      window.toast({
        description: 'Post pinned!',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
    onError: () => {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const unpinPostMutation = useMutation({
    mutationFn: () =>
      AxiosInstance.put(`/admin/communities/amity-post/${post.postId}`, {
        tags: [...post.tags.filter(tag => tag !== 'pinned')],
      }),
    onSuccess: () => {
      onUnpinPost();
      window.toast({
        description: 'Post unpinned!',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
    onError: () => {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const deletePostMutation = useMutation({
    mutationFn: () => AxiosInstance.delete(`/amity-posts/${post.postId}`),
    onSuccess: () => {
      onDeletePost();
      window.toast({
        description: 'Post deleted!',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
    onError: e => {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const likePostMutation = useMutation({
    mutationFn: async () => {
      onLikePost();
      await ReactionRepository.addReaction('post', post.postId, 'like');
    },
    onError(e) {
      onUnlikePost();
    },
  });

  const unlikePostMutation = useMutation({
    mutationFn: async () => {
      onUnlikePost();
      await ReactionRepository.removeReaction('post', post.postId, 'like');
    },
    onError(e) {
      onLikePost();
    },
  });

  const addNewCommentMutation = useMutation({
    mutationFn: async () => {
      await CommentRepository.createComment({
        referenceType: 'post',
        referenceId: post.postId,
        data: {
          text: commentText,
        },
        metadata: {
          admin: true,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      setPostCommentsCount(post.postId, post.commentsCount + 1);
      setCommentText('');
      commentsContainerRef.current?.scrollIntoView({behavior: 'smooth'});
    },
    onError(e) {
      window.toast({
        description: 'Cannot create comment. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  function updateComment(amityComment: AmitySDKcommentType) {
    setComments(prev =>
      prev!.map(comment => {
        if (comment._id === amityComment._id) {
          return amityComment;
        }
        return comment;
      }),
    );
  }

  function handleLikeClick() {
    if (post.myReactions?.includes('like')) {
      unlikePostMutation.mutate();
    } else {
      likePostMutation.mutate();
    }
  }

  function deleteComment(amityComment: AmitySDKcommentType) {
    setComments(prev => prev!.filter(comment => comment._id !== amityComment.commentId));
    setPostCommentsCount(
      post.postId,
      post.commentsCount - (amityComment.childrenNumber + 1),
    );
  }

  return (
    <Box
      borderRadius="24px"
      padding="16px 16px 12px 16px"
      bg="white"
      boxShadow="0 4px 40px rgba(0, 0, 0, 0.07)" // Drop shadow as specified
      mb={4}>
      <DeleteConfirmPopup
        isOpen={isOpen}
        loading={deletePostMutation.isPending}
        headerText="Delete Post"
        onCancel={onClose}
        onDelete={deletePostMutation.mutate}
        cancelRef={null}
      />

      <EditPostModal
        post={post}
        isOpen={isOpenEdiPost}
        onClose={onCloseEditPost}
        onUpdatePost={onUpdatePost}
      />

      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Avatar
            src={post.creator?.avatarCustomUrl}
            name={post.creator?.displayName}
            width="42px"
            height="42px"
          />
          <Box ml="3">
            <HStack>
              <Text fontSize="16px" lineHeight="18px" fontWeight="600" color="#1B1A1A">
                {post.creator?.displayName}
              </Text>
              {post.tags.includes('admin') && <CoachBadge />}
            </HStack>
            <Text
              fontSize="10px"
              lineHeight="14px"
              fontWeight="600"
              color="#8E959E"
              letterSpacing="9%"
              textAlign="left">
              <ReactTimeago date={post.createdAt} minPeriod={60} formatter={agoFormatter} />
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center">
          {isPinned && (
            <Button
              size="sm"
              colorScheme="red"
              variant="ghost"
              leftIcon={<FaThumbtack />}
              onClick={() => unpinPostMutation.mutate()}
              isDisabled={unpinPostMutation.isPending}
              isLoading={unpinPostMutation.isPending}
              mr="2">
              Unpin
            </Button>
          )}

          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FaEllipsisV />}
              variant="shadow"
            />
            <MenuList>
              <MenuItem icon={<FaEdit />} onClick={onOpenEditPost}>
                Edit
              </MenuItem>
              {!isPinned && (
                <MenuItem
                  icon={<FaThumbtack />}
                  onClick={() => pinPostMutation.mutate()}
                  isDisabled={pinPostMutation.isPending}>
                  Pin
                </MenuItem>
              )}
              <MenuItem icon={<FaTrash />} color="red" onClick={onOpen}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Box>
        {post.metadata?.showImagesOnTop &&
          post.metadata?.postImages?.map((url: string) => (
            <Image
              key={url}
              src={url}
              alt="Post image"
              mt="3"
              ml={3}
              borderRadius="lg"
              maxHeight={400}
              aspectRatio={1}
            />
          ))}
        <Box maxHeight="300px" overflowY="scroll">
          <Prose>
            <style>
              {`
              .prose {
                padding-left: 0;
              }
            `}
              {sharedMarkdownStylesString}
            </style>
            <MDXEditor
              readOnly
              markdown={post.data.text}
              ref={mdxEditorRef}
              contentEditableClassName="prose"
              plugins={[linkPlugin(), linkDialogPlugin(), headingsPlugin()]}
            />
          </Prose>
        </Box>
        {!post.metadata?.showImagesOnTop &&
          post.metadata?.postImages?.map((url: string) => (
            <Image
              key={url}
              src={url}
              alt="Post image"
              mt="3"
              ml={3}
              borderRadius="lg"
              maxHeight={400}
              aspectRatio={1}
            />
          ))}
      </Box>
      <HStack>
        <HStack>
          <IconButton
            aria-label="Like"
            icon={
              post.myReactions?.includes('like') ? (
                <FaHeart color="red" />
              ) : (
                <FaRegHeart color="gray.500" />
              )
            }
            variant="ghost"
            size="sm"
            minWidth={0}
            onClick={handleLikeClick}
            _hover={{bgColor: 'none'}}
          />
          <Text fontSize="14px" lineHeight="18px" fontWeight="400" color="#1B1A1A">
            {post.reactions?.like > 0 ? `${post.reactions?.like} Likes` : 'Like'}
          </Text>
        </HStack>

        <Button
          variant="ghost"
          onClick={() => setIsViewingComments(prev => !prev)}
          leftIcon={<FaRegComment />}
          fontSize="14px"
          lineHeight="18px"
          fontWeight="400"
          color="#1B1A1A">
          {post.commentsCount > 0 ? post.commentsCount + ' Comments' : 'Comment'}
        </Button>
      </HStack>

      {isViewingComments && (
        <Box ref={commentsContainerRef}>
          <HStack w="100%" border="1px solid #E6EAF1"></HStack>

          {loadingComments && comments === null && (
            <Flex justifyContent="center" alignItems="center" height="100%" py={2}>
              <HStack>
                <Text>Loading comments...</Text>
                <Spinner color="blue.500" />
              </HStack>
            </Flex>
          )}

          {comments?.map(comment => (
            <SingleCommentThread
              key={comment._id}
              parentComment={comment}
              handleCommentDelete={() => deleteComment(comment)}
              updateParentComment={updateComment}
              onAddReply={() => setPostCommentsCount(post.postId, post.commentsCount + 1)}
              onDeleteReply={() =>
                setPostCommentsCount(post.postId, post.commentsCount - 1)
              }
            />
          ))}

          {hasMoreComments && (
            <Flex justifyContent="center" alignItems="center">
              <Button onClick={() => loadMoreFnRef.current?.()} isLoading={loadingComments}>
                Load more
              </Button>
            </Flex>
          )}

          <HStack w="full" borderTopWidth="1px" paddingTop={6} paddingBottom={4}>
            <NotificationSendCommentInput
              comment={commentText}
              setComment={setCommentText}
              loadingCreatingReply={addNewCommentMutation.isPending}
              handlePostClick={addNewCommentMutation.mutate}
            />
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default PostDetails;
