import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Input,
  Grid,
  GridItem,
  FormControl,
  Button,
  FormErrorMessage,
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import UsersTableActionsColumn from '../../../components/ResultsTable/Users/UsersTableActionsColumn.tsx';

import {ChevronRightIcon} from '@chakra-ui/icons';

import {useEffect, useState} from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosInstance} from '../../../api/_AxiosInstance.ts';
import {SingleUser} from '../types/GetUsersResponse.ts';
import {Link as ReactRouterLink, useParams} from 'react-router-dom';
import './userDetails.css';
import {isValidEmail} from '../../../helpers/regex.ts';

const getStageLabel = (stage: string) => {
  switch (stage) {
    case 'trying_to_conceive':
      return 'Trying To Conceive';
    case 'pregnant':
      return 'Pregnant';
    case 'postpartum':
      return 'Postpartum';
    default:
      return '';
  }
};

export default function UserDetailsPage() {
  const {id} = useParams();

  const {data: userData, refetch} = useQuery({
    queryKey: [id],
    queryFn: async () => {
      const {data} = await AxiosInstance.get<SingleUser>(`/user/${id}`);
      return data;
    },
  });

  const updateEmailMutation = useMutation({
    mutationFn: async () => {
      await AxiosInstance.post(`/user/${id}/email`, {email: email});
    },
    onError: () => {
      if (userData?.email) {
        setEmail(userData?.email);
      }
    },
    onSuccess: async () => {
      await refetch();
      window.toast({
        description: 'Email updated successfully.',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const [email, setEmail] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (userData?.email && userData.email !== email && isValidEmail(email)) {
      setSaveDisabled(false);
    } else if (!saveDisabled) {
      setSaveDisabled(true);
    }
  }, [email, userData]);

  useEffect(() => {
    if (userData?.email) {
      setEmail(userData.email);
    }
  }, [userData]);

  const saveChanges = () => {
    updateEmailMutation.mutate();
  };

  return (
    <Box height="auto" maxWidth={1200}>
      <Flex justifyContent="space-between">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={ReactRouterLink} to="/users" color="gray.500">
              Users
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#" color="gray.500">
              View
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex>
          {userData && (
            <UsersTableActionsColumn
              user={userData}
              changeUserRole={() => refetch()}
              onSubscriptionChange={() => refetch()}
              displayAsMenu
            />
          )}
          <Button
            onClick={saveChanges}
            colorScheme="black"
            isDisabled={saveDisabled}
            ml={2}>
            Save
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Avatar
          src={userData?.profilePictureUrl ? userData.profilePictureUrl : ''}
          name={
            userData?.firstName && userData?.lastName
              ? `${userData?.firstName} ${userData.lastName}`
              : ''
          }
          width="42px"
          height="42px"
          mr={4}
        />
        <Heading mb={4}>{`${userData?.firstName} ${userData?.lastName}`}</Heading>
      </Flex>
      <Card>
        <CardBody>
          {userData && (
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(2, 1fr)"
              gap={5}
              maxWidth="container.xl">
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Name
                </Heading>
                <Input
                  placeholder="Name"
                  value={`${userData.firstName} ${userData.lastName}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Email
                </Heading>
                <FormControl isInvalid={!!(email && !isValidEmail(email))}>
                  <Input
                    placeholder="Enter email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={'user-details-input'}
                    type="email"
                  />
                  <FormErrorMessage>Email is invalid</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Stage
                </Heading>
                <Input
                  placeholder="Stage"
                  value={`${getStageLabel(userData?.currentStage)}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Role
                </Heading>
                <Input
                  placeholder="Role"
                  value={`${userData?.role === 'admin' ? 'Admin' : 'User'}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
            </Grid>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
