/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  Box,
  Avatar,
  Text,
  HStack,
  VStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  BoxProps,
  Button,
} from '@chakra-ui/react';
import {FiMoreVertical} from 'react-icons/fi';
import {FaCircle, FaRegHeart, FaHeart} from 'react-icons/fa';
import TimeAgo from 'react-timeago';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import CoachBadge from '../Common/Badges/CoachBadge';
import TextareaAutosize from 'react-textarea-autosize';

const formatter = buildFormatter({
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: '',
  seconds: 'just now',
  minute: '1 min ago',
  minutes: '%d mins ago',
  hour: '1 hour ago',
  hours: '%d hours ago',
  day: '1 day ago',
  days: '%d days ago',
  month: '1 month ago',
  months: '%d months ago',
  year: '1 year ago',
  years: '%d years ago',
  wordSeparator: ' ',
});

interface CommentItemProps {
  username: string;
  avatarUrl: string;
  timeAgo: string;
  comment: string;
  children?: React.ReactNode;
  numberOfReplies?: number;
  isHighlighted?: boolean;
  showReply?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  isLoadingDelete?: boolean;
  componentProps?: Partial<BoxProps>;
  likeCount?: number;
  isLiked?: boolean;
  showLike?: boolean;
  isCoach?: boolean;
  onDeleteclick?: () => void;
  onEditClick?: () => void;
  onLikeClick?: () => void;
  onReplyClick?: () => void;
  isEditing?: boolean;
  editText?: string;
  onEditTextChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onCancelEdit?: () => void;
  onSaveEdit?: () => void;
  updatedAt: string;
}

const CommentItem: React.FC<CommentItemProps> = ({
  username,
  avatarUrl,
  timeAgo,
  comment,
  children,
  isHighlighted = false,
  showReply = false,
  canDelete = false,
  canEdit = false,
  numberOfReplies = 0,
  onReplyClick,
  componentProps = {},
  onEditClick,
  onDeleteclick,
  onLikeClick,
  showLike = false,
  likeCount = 0,
  isLiked = false,
  isCoach = false,
  isEditing = false,
  editText = '',
  onEditTextChange,
  onCancelEdit,
  onSaveEdit,
  updatedAt,
}) => {
  return (
    <Box
      margin={0}
      paddingX={4}
      paddingY={2}
      {...componentProps}
      bg={isHighlighted ? '#FBF4EE' : 'white'}
      borderRadius="16px">
      <HStack align="start" spacing={4} w="full" borderRadius="lg">
        <Avatar size="sm" name={username} src={avatarUrl} />
        <VStack align="start" w="full" gap={0}>
          <HStack w="full" justify="space-between">
            <HStack>
              <HStack>
                <Text fontWeight="bold">{username}</Text>
                {isCoach && <CoachBadge />}
              </HStack>
              <FaCircle size="4px" color="gray" />
              <Text fontSize="sm" color="gray.500">
                <TimeAgo date={timeAgo} minPeriod={60} formatter={formatter} />
              </Text>
              {timeAgo !== updatedAt && (
                <>
                  <FaCircle size="4px" color="gray" />
                  <Text fontSize="sm" color="gray.500">
                    edited
                  </Text>
                </>
              )}
            </HStack>
            {(canEdit || canDelete) && !isEditing && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiMoreVertical />}
                  variant="ghost"
                  size="sm"
                />
                <MenuList>
                  {canDelete && <MenuItem onClick={onDeleteclick}>Delete</MenuItem>}
                  {canEdit && <MenuItem onClick={onEditClick}>Edit</MenuItem>}
                </MenuList>
              </Menu>
            )}
          </HStack>

          {isEditing ? (
            <VStack w="full" spacing={2}>
              <TextareaAutosize
                minRows={1}
                placeholder="Add a comment..."
                value={editText}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onEditTextChange?.(e)
                }
                style={{
                  borderRadius: '8px',
                  borderColor: 'gray',
                  borderWidth: 1,
                  width: '100%',
                  padding: '0.5rem',
                }}
              />
              <HStack w="full" justify="flex-end">
                <Button size="sm" variant="ghost" onClick={onCancelEdit}>
                  Cancel
                </Button>
                <Button size="sm" colorScheme="black" onClick={onSaveEdit}>
                  Save
                </Button>
              </HStack>
            </VStack>
          ) : (
            <Text
              lineHeight="18px"
              marginRight={2}
              wordBreak={'break-word'}
              whiteSpace="pre-wrap">
              {comment}
            </Text>
          )}

          <HStack>
            {showLike && (
              <>
                <IconButton
                  aria-label="Like"
                  icon={isLiked ? <FaHeart color="red" /> : <FaRegHeart color="gray.500" />}
                  variant="ghost"
                  size="sm"
                  minWidth={0}
                  onClick={onLikeClick}
                  _hover={{bgColor: 'none'}}
                />
                <Text fontSize="sm" color="gray.500" marginRight={2}>
                  {likeCount > 0 ? likeCount : ''}
                </Text>
              </>
            )}
            {showReply && (
              <Text fontSize="sm" color="gray.500" cursor="pointer" onClick={onReplyClick}>
                Reply {numberOfReplies > 0 && `(${numberOfReplies})`}
              </Text>
            )}
          </HStack>
        </VStack>
      </HStack>
      <Box ml={8}>{children}</Box>
    </Box>
  );
};

export default CommentItem;
